@import url("https://fonts.googleapis.com/css?family=Muli:400,600,700,800&display=swap");

.App {
  text-align: center;
}

h1,
h2,
h3 {
  font-weight: 800;
}

label {
  font-weight: 800;
}
